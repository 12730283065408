import { IRouterOption } from 'src/router'
import { WorkingEmails } from './components/list'
import { EPaths } from './routes.path'

export enum ERoutes {
  WORKING_EMAILS = 'WORKING_EMAILS'
}

export const Routes: IRouterOption[] = [
  {
    path: EPaths.WORKING_EMAILS,
    component: WorkingEmails,
    name: ERoutes.WORKING_EMAILS,
    exact: true,
    meta: {}
  }
]

export const WorkingEmailsModule = {
  ERoutes,
  Routes
}
