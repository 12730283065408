import { Pagination, Table } from 'antd'
import { FC, useCallback, useEffect, useMemo, useState } from 'react'
import { takeUntil } from 'rxjs'
import { WorkingEmailApi } from 'src/api'
import { IPaginateCallback, usePaginateParams, useUnsubscribe } from 'src/hooks'
import { IPaginationResponse, IWorkingEmailModel } from 'src/interfaces'
import { BreadcrumbService, PaginationService } from 'src/services'
import { GlobalAction, GlobalState, useStore } from 'src/store'
import { EPaths } from '../../routes.path'
import { renderColumns } from './columns'

const PAGE_TITLE = 'Working Emails'

export const WorkingEmails: FC = () => {
  const unsubscribe$ = useUnsubscribe()
  const { value: { loading } } = useStore(GlobalState)
  const _paginationService = useMemo(() => new PaginationService(WorkingEmailApi), [])
  const [dataSource, setDataSource] = useState<IPaginationResponse<IWorkingEmailModel>>({
    total: 1,
    rows: []
  })

  const fetch = useCallback<IPaginateCallback>(
    (params) => _paginationService.paging(params),
    [_paginationService]
  )

  const { pagination, pushPagination } = usePaginateParams(fetch)

  useEffect(() => {
    _paginationService
      .loading$
      .pipe(takeUntil(unsubscribe$))
      .subscribe((value) => GlobalAction.setLoading(value))
    _paginationService
      .pagination$
      .pipe(takeUntil(unsubscribe$))
      .subscribe((data) => setDataSource(data))
  }, [_paginationService, unsubscribe$])

  /**
   * breadcrumb
   */
  useEffect(() => {
    BreadcrumbService.items = [{
      route: EPaths.WORKING_EMAILS,
      label: PAGE_TITLE
    }]
    return () => {
      BreadcrumbService.items = []
    }
  }, [])

  return (
    <section className="fx fx-column fx-extend">
      <Table
        bordered
        rowKey="id"
        className="fx-extend"
        loading={loading}
        columns={renderColumns()}
        dataSource={dataSource.rows}
        pagination={false}
      />

      <Pagination
        className="fx-as-end mt-3"
        disabled={loading}
        total={dataSource.total}
        current={pagination.page}
        pageSize={pagination.limit}
        {
          ...{
            ...pagination,
            onChange(page, limit) {
              pushPagination({
                ...pagination,
                page,
                limit
              })
            }
          }
        }
      />
    </section>
  )
}
