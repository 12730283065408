import { TableProps } from 'antd'
import { Link } from 'react-router-dom'
import { IWorkingEmailModel } from 'src/interfaces'
import { TimeZoneService } from 'src/services'

export const renderColumns = (): Required<TableProps<IWorkingEmailModel>>['columns'] => [
  {
    title: 'ID',
    dataIndex: 'id',
    key: 'id',
    render: (val, doc) => <Link to={`/candidates/${doc.userId}/dashboard`}>{val}</Link>
  },
  {
    title: 'User',
    dataIndex: 'fullName',
    render: (val, doc) => <Link to={`/candidates/${doc.userId}/dashboard`}>{val}</Link>
  },
  {
    title: 'Working email',
    dataIndex: 'workingEmail',
    key: 'workingEmail'
  },
  {
    title: 'Email',
    dataIndex: 'email',
    key: 'email'
  },
  {
    title: 'Phone number',
    dataIndex: 'phoneNumber',
    key: 'phoneNumber'
  },
  {
    title: 'CreatedAt',
    dataIndex: 'created',
    key: 'created',
    render: (val: string) => TimeZoneService.format(val, 'MMM D, y HH:mm:ss')
  }
]
