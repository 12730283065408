import { AdminModule } from 'src/modules/admin/routes'
import { AffiliateFormModule } from 'src/modules/affiliate-forms/routes'
import { AIModule } from 'src/modules/ai/routes'
import { AnalyticsModule } from 'src/modules/analytics/routes'
import { ArchivedCampaignModule } from 'src/modules/archived-campaign/routes'
import { AuthModule } from 'src/modules/auth/routes'
import { BlogModule } from 'src/modules/blog/routes'
import { CalendarModule } from 'src/modules/calendar/routes'
import { CampaignSubmissionModule } from 'src/modules/campaign-submission/routes'
import { CampaignModule } from 'src/modules/campaign/routes'
import { CandidateModule } from 'src/modules/candidate/routes'
import { CompanyModule } from 'src/modules/company/routes'
import { ContactFormModule } from 'src/modules/contact-forms/routes'
import { NotFound } from 'src/modules/core/components/404'
import { CoreModule } from 'src/modules/core/routes'
import { ExpandYourAreaModule } from 'src/modules/expand-your-area/routes'
import { FeedbackModule } from 'src/modules/feedback/routes'
import { HashtagModule } from 'src/modules/hashtag/routes'
import { HiringManagerModule } from 'src/modules/hiring-manager/routes'
import { MessengerModule } from 'src/modules/messenger/routes'
import { NotificationTemplateModule } from 'src/modules/notification-template/routes'
import { PromoCodeModule } from 'src/modules/promo-code/routes'
import { QueueModule } from 'src/modules/queue/routes'
import { ScrapedContactModule } from 'src/modules/scraped-contacts/routes'
import { ScrapedJobModule } from 'src/modules/scraped-job/routes'
import { SettingModule } from 'src/modules/settings/routes'
import { VibeModule } from 'src/modules/vibe/routes'
import { VideoModule } from 'src/modules/video/routes'
import { WorkingEmailsModule } from 'src/modules/working-emails/routes'
import { IRouterOption } from './interface'

export const ERoutes = {
  ...AdminModule.ERoutes,
  ...AffiliateFormModule.ERoutes,
  ...AIModule.ERoutes,
  ...AnalyticsModule.ERoutes,
  ...ArchivedCampaignModule.ERoutes,
  ...AuthModule.ERoutes,
  ...BlogModule.ERoutes,
  ...CalendarModule.ERoutes,
  ...CampaignModule.ERoutes,
  ...CampaignSubmissionModule.ERoutes,
  ...CandidateModule.ERoutes,
  ...CompanyModule.ERoutes,
  ...ContactFormModule.ERoutes,
  ...CoreModule.ERoutes,
  ...ExpandYourAreaModule.ERoutes,
  ...FeedbackModule.ERoutes,
  ...HashtagModule.ERoutes,
  ...HiringManagerModule.ERoutes,
  ...MessengerModule.ERoutes,
  ...NotificationTemplateModule.ERoutes,
  ...PromoCodeModule.ERoutes,
  ...QueueModule.ERoutes,
  ...ScrapedContactModule.ERoutes,
  ...ScrapedJobModule.ERoutes,
  ...SettingModule.ERoutes,
  ...VibeModule.ERoutes,
  ...VideoModule.ERoutes,
  ...WorkingEmailsModule.ERoutes
}

export const routes: IRouterOption[] = [
  ...AdminModule.Routes,
  ...AffiliateFormModule.Routes,
  ...AIModule.Routes,
  ...AnalyticsModule.Routes,
  ...ArchivedCampaignModule.Routes,
  ...AuthModule.Routes,
  ...BlogModule.Routes,
  ...CalendarModule.Routes,
  ...CampaignModule.Routes,
  ...CampaignSubmissionModule.Routes,
  ...CandidateModule.Routes,
  ...CompanyModule.Routes,
  ...ContactFormModule.Routes,
  ...CoreModule.Routes,
  ...ExpandYourAreaModule.Routes,
  ...FeedbackModule.Routes,
  ...HashtagModule.Routes,
  ...HiringManagerModule.Routes,
  ...MessengerModule.Routes,
  ...NotificationTemplateModule.Routes,
  ...PromoCodeModule.Routes,
  ...QueueModule.Routes,
  ...ScrapedContactModule.Routes,
  ...ScrapedJobModule.Routes,
  ...SettingModule.Routes,
  ...VibeModule.Routes,
  ...VideoModule.Routes,
  ...WorkingEmailsModule.Routes,

  // last route handle 404 error
  {
    path: '*',
    component: NotFound
  }
]
