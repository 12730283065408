import axios from 'axios'
import { IPaginationQuery, IWorkingEmailModel, TAxiosResponsePagination } from 'src/interfaces'

export class WorkingEmailApi {
  static readonly _prefix = '/working-email'

  static index(params: IPaginationQuery = {}): TAxiosResponsePagination<IWorkingEmailModel> {
    return axios.get(`${WorkingEmailApi._prefix}`, { params })
  }
}
